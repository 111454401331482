.App {
  text-align: center;
}

::-webkit-input-placeholder {
  color: #2751A3;

}

input, select, option {
  height: 5vh;
  border-radius: 25px ;
  border-color: #2751A3;
  padding-left: 1vw;
  font-size: 15px;
}

button {
  height: 5vh;
  width: 10vw;
  border-radius: 25px ;
  border-color: #2751A3;
  font-size: 15px;
  background-color: #2751A3;
  color: white;
}

