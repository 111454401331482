body {
  margin: 0;
  font-family: "Lexend", sans-serif;

}

h1 {
  font-family: "Poppins", sans-serif;
  font-size: 5em;
}


